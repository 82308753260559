<template>
  <div>
    <v-menu
      transition="slide-y-transition"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          rounded
          outlined
          dark
          v-bind="attrs"
          v-on="on"
        >
          Atualizar status
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in statusItems"
          :key="i"
          link
          @click="updateMultipleStatus(item.value)"
        >
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

  <script>
  export default {
    props: {
      selectedItems: {
        type: Array,
        default: () => []
      },
    },
    data: () => ({
      statusItems: [
        { text: 'Ok', value: 1 },
        { text: 'Consultar', value: 2 },
        { text: 'Sem Estoque', value: 3 },
        { text: 'Fora de linha', value: 4 },
      ],
    }),
    methods: {
    async updateMultipleStatus(value) {
        let selectedIds = this.selectedItems.map((item) => {
          return item.product_id
        })
        const requests = selectedIds.map((selectedId) => {
          return this.$api.patch('products/vendor-status', {
            vendor_id: parseInt(this.$route.params.id, 10),
            product_id: selectedId,
            status: value,
          });
        });

        await Promise.all(requests).then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Status alterado com sucesso',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
                this.$emit('clearSelected');
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar alterar status',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          });
      },
      deleteMultiple() {
        let selectedIds = this.selectedItems.map((item) => {
          return item.id
        })
        this.$store.commit('setModal', {
          show: true,
          component: 'warning',
          text: 'Atenção!',
          caption: `Deseja mesmo deletar ${this.selectedItems.length} Fornecedores?`,
          confirm: () => {
            this.$api
              .post(`vendors/massdelete`, selectedIds)
              .then(() => {
                this.$store.commit('setModal', {
                  show: true,
                  component: 'success',
                  text: 'Fornecedores excluidos com sucesso',
                  confirm: () => {
                    this.$store.commit('setModal', {
                      show: false,
                    })
                    this.$emit('modalClicked')
                  },
                })
              })
              .catch(() => {
                this.$store.commit('setModal', {
                  show: true,
                  component: 'error',
                  text: 'Ops! Falha ao tentar excluir estes Fornecedores',
                  confirm: () => {
                    this.$store.commit('setModal', {
                      show: false,
                    })
                  },
                })
              })
          },
          negate: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      },
    },
  }
  </script>
